<template>
  <div id="app">
    <Header v-if="headerShow"/>
    <transition name="router-fade" mode="out-in">
        <router-view></router-view>
    </transition>
    <Footer v-if="headerShow"/>
  </div>
</template>

<script>
import Header from '@/components/header/Header.vue'
import Footer from '@/components/footer/Footer.vue'
import {mapGetters , mapActions} from 'vuex'
export default {
  components: {
    Header,
    Footer
  },
  computed:{
    ...mapGetters([
      "headerShow"
    ])
  },

  created(){
    // 更新页面缓存中的信息
    if (sessionStorage.getItem("store") ) {
      this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    }
    // 页面刷新将VUEX 信息保存到sessionStroage
    window.addEventListener("beforeunload",()=>{
      sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })

    this.changeHeaderShow({
        status:true
    })


  },
  methods:{    
    ...mapActions([
        'changeHeaderShow',
        'changeCart'
    ])
  }
}
</script>

<style lang="stylus" scoped>
#app >>> .el-breadcrumb__inner a:hover,#app >>> .el-breadcrumb__inner.is-link:hover
  color #b19d75
#app >>> .el-tabs__item.is-active,#app >>> .el-tabs__item:hover
  color #b19d75
#app >>> .el-input__inner
  border-radius 0
</style>

