import * as types from './mutation-types'
const mutations = {
    [types.SET_CART](state,cartItems){
        state.cartItems = cartItems
    },
    [types.SET_HEADER](state,status){
        state.headerShow = status
    },
    [types.SET_WEIGHT](state,weight){
        state.weight = weight
    },
    [types.SET_LOGIN_STATUS](state,loginStatus){
        state.loginStatus = loginStatus
    },
    [types.SET_USER_ID](state,userId){
        state.userId = userId
    }

}

export default mutations