import * as types from './mutation-types'
// 更改购物车产品数量
export const changeCart = function({commit},{cartItems}){
    commit(types.SET_CART,cartItems)
}
// header footer 显示/隐藏
export const changeHeaderShow = function({commit},{status}){
    commit(types.SET_HEADER,status)
}
// 购物车产品重量
export const changeWeight = function({commit},{weight}){
    commit(types.SET_WEIGHT,weight)
}
// 用户登录状态
export const changeLogin = function({commit},{loginStatus}){
    commit(types.SET_LOGIN_STATUS,loginStatus)
}
//  登录后用户ID
export const changeUserId = function({commit},{userId}){
    commit(types.SET_USER_ID,userId)
}
