import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: '/collection/:title/:id',
    name: 'collection',
    component: () => import("../views/collection/Collection.vue"),
  },
  {
    path: '/details/:title/:id',
    name: 'detail',
    component: () => import("../views/detail/Detail.vue"),
  },
  {
    path:'/cart',
    name:'cart',
    component:() => import("../views/cart/Cart.vue")
  },
  {
    path:'/checkout',
    name:'checkout',
    component:() => import("../views/checkout/Checkout.vue")
  },
  {
    path:'/account/login',
    name:'login',
    component:()=> import ("../views/user/Login.vue")
  },
  {
    path:'/account/register',
    name:'register',
    component:()=> import ("../views/user/Register.vue")
  },
  {
    path:'/account/order',
    name:'order',
    component:()=> import ("../views/user/Order.vue")
  }



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
