<template>
    <header class="header-container">
        <div class="headerTip">{{headerTip}}</div>
        <div class="header-content">
            <router-link to="/" class="logo-img">{{logoInfo}}</router-link>
            <nav class="menu-content">
                
                    <router-link   
                    v-for="(item,index) in menuList" :key="item.name"
                    :to="{name:'collection',params:{title:item.handle,id:item.id}}"
                     class="menu-item"
                    :class="{itemHover:hoverClass === index}" 
                    @mouseenter.native="addClassload(index)" 
                    @mouseleave.native="removeClassload(index)">
                        {{item.name}}
                        <div class="menu-item-panel" v-if="item.children.length>0">
                            <router-link :to="{name:'collection',params:{title:i.handle,id:i.id}}" class="menu-children-item" v-for="i in item.children" :key="i.title">
                                {{i.title}}
                            </router-link>
                        </div>
                    </router-link>
                    
            </nav>
            <div class="action-content">
                <div class="action-item">
                    <icon name="search" :w="30" :h="30"></icon>
                </div>
                <div class="action-item" @click="goAccount">
                    <icon name="account" :w="30" :h="30"></icon>
                </div>
                <div class="action-item" @click="goCart">
                    <icon name="cart-Empty" :w="30" :h="30"></icon>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {getNavication} from '@/api/mall/index.js'
import {mapGetters} from 'vuex'
export default {
    data() {
      return {
        headerTip:"Cart total over $2999 share $500 off, Code: 500OFF / Cart total over $4999 share $1000 off, Code: 1000OFF",
        logoInfo:"footthey",
        hoverClass:99,
        menuList:[]
      };
    },
    mounted() {
        this.initMenuList()
    },
    computed:{
        ...mapGetters([
            'loginStatus',
            'userId'
        ])
    },
    methods: {
      addClassload(index){          
          this.hoverClass = index
      },
      removeClassload(index){
          this.hoverClass = ''
      },
      initMenuList(){
          getNavication().then(res=>{
              this.menuList = res.data.list
          })
      },
      goAccount(){
          if(this.loginStatus){
              this.$router.push({name:'order'})
          }else{
              this.$router.push({name:'register'})
          }
      },
      goCart(){
          this.$router.push({name:'cart'})
      }
    }
  }
</script>

<style lang="stylus" scoped>
.headerTip 
    background-color #000000
    height 3.75rem
    line-height 3.75rem
    font-size .875rem
    text-align center
.header-content
    display flex
    height 5.625rem
    align-items center
    justify-content space-between
    background #fff
    padding 0 13.75%
.logo-img 
    width 16.875rem
    color #000
    font-size 2.1875rem
    text-transform uppercase
    letter-spacing 1px
nav.menu-content 
    flex 1
    display flex
    color #000
    align-items center
    font-size .875rem
    flex-wrap wrap
    .menu-item
        padding .3125rem 1.25rem
        position relative  
        color #000
        text-transform capitalize              
        .menu-item-panel
            display:none
            position absolute
            padding .9375rem
            width 12.5rem
            background #fff
            z-index 9
            .menu-children-item                
                padding 5px 0
                font-size .875rem
                color #666
                display block
    .menu-item.itemHover
        .menu-item-panel
            display block
.action-content 
    display flex
    color #000
    .action-item
        padding 5px
        cursor pointer
        &:hover
            color #b19d75
        
</style>
