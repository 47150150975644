import request from '@/api/request'
// 获取类目
export function getProductList(data) {
    return request({
      url: '/api/getProductList',
      method: 'post',
      data:  'param='+ data
    })
  }
// 获取导航栏
  export function getNavication() {
    return request({
      url: '/api/getNavication',
      method: 'post',
      data:  'param='
    })
  }

//   获取详情
export function getProductDetail(data) {
    return request({
      url: '/api/getProductDetail',
      method: 'post',
      data:  'param='+ data
    })
}

//   更新购物车
export function caculateCartPrice(data) {
    return request({
      url: '/api/caculateCartPrice',
      method: 'post',
      data:  'param='+ data
    })
}

// 查看支持配送的国家
export function findAll() {
    return request({
      url: '/api/findAll',
      method: 'post',
      data:  'param='
    })
}

// 获取运费
export function getShipping(data) {
    return request({
      url: '/api/getShipping',
      method: 'post',
      data:  'param=' + data
    })
}

// paypal支付
export function payWithPaypal(data) {
    return request({
      url: '/api/paypal',
      method: 'post',
      data:  'param=' + data
    })
}
// stripe 支付
export function payWithStripe(data) {
    return request({
      url: '/api/stripe/checkout',
      method: 'post',
      data:  'param=' + data
    })
}

// 登录
export function login(data) {
    return request({
      url: '/api/login',
      method: 'post',
      data:  'param=' + data
    })
}
// 注册
export function register(data) {
    return request({
      url: '/api/regist',
      method: 'post',
      data:  'param=' + data
    })
}
// 获取用户订单
export function getOrder(data) {
    return request({
      url: '/api/getCustomerOrders',
      method: 'post',
      data:  'param=' + data
    })
}

// 获取订单详情
export function getOrderDetail(data) {
    return request({
      url: '/api/customer/findOrder',
      method: 'post',
      data:  'param=' + data
    })
}