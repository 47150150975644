import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV == "development" ? "http://192.168.0.151:8081" : "http://toolsapi.knockclick.com", // api 的 base_url
  timeout: 0 // 请求超时时间
})

service.defaults.withCredentials=true;

// request拦截器
// service.interceptors.request.use(
//   config => {
//     if (store.getters.token) {
//      config.headers['X-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
//    }
//     return config
//  },
//   error => {
//     // Do something with request error
//     console.log(error) // for debug
//     Promise.reject(error)
//   }
// )

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response
    
    if (res.status!= 200) {
      Message({
        message: '请求失败,errorCode'+res.data.errorCode,
        type: 'error',
        duration: 5 * 1000
      })
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      // if (res.errcode === 40001) {
      //   MessageBox.confirm(
      //     'token无效，或者重新登录',
      //     '确定登出',
      //     {
      //       confirmButtonText: '重新登录',
      //       cancelButtonText: '取消',
      //       type: 'warning'
      //     }
      //   ).then(() => {
          
      //   })
      // }
      // return Promise.reject('error')
    }else if(res.data.errorCode == 403){
      Message({
        message:'token过期，请重新登陆',
        type:'error',
        duration:3000
      })
      sessionStorage.clear()
      window.location.href='/'
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message:'err' +  error,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
